import "./index.scss";
import MPPerkProgressMilestone from "../../MPPerkProgressMilestone";
import MPSeeAllPerksCta from "../MPSeeAllPerksCta";
import MPPerksDetailsList from "../MPPerksContainer/MPPerksDetailsList";
import { AccountStateType, ContentStateType } from "../../../state/InitialState";
import { useContext } from "react";
import { PerksContext } from "../../../context/PerksContext";
import { ContentContext } from "../../../context/ContentContext";
import UserAccountContext from "../../../context/UserAccountContext";
import MPNextPerksMilestone from "../MPNextPerksMilestone";
import { PerkBenefitItemProps } from "../MPPerksContainer/MPPerksDetailsList/PerksBenefitItem";

const MPMilestoneContainer = () => {
  const { contentState }: { contentState: ContentStateType } = useContext(ContentContext);
  const { accountState }: { accountState: AccountStateType } = useContext(UserAccountContext);

  const {
    perksState: { perks },
  } = useContext(PerksContext);

  const getMilestone = (milestoneId: number = 0) => {
    const milestone = contentState?.Content[5]?.milestones?.find((milestone) => milestone.id === milestoneId);
    return milestone;
  };

  const getPerk = (perkId: number) => {
    const perk = contentState?.Content[4]?.perks?.find((perk) => perk.id === perkId);

    if (perk) {
      return perk;
    }
  };

  const emptyPerks = [
    {
      perkBenefitId: 0,
      perkName: "",
      perkDescription: "",
      isEnabled: true,
    },
  ];

  return (
    <>
      <div className="perk-sub-title">Current status</div>
      <div className="current-status-container">
        <MPPerksDetailsList
          isCurrentMilestone
          mileStoneStatus={accountState?.MileagePlanDetails?.TierName}
          oneworldTierstatus={accountState?.MileagePlanDetails?.oneWorldTier}
          qualifyingMiles={perks?.currentPerks?.milestoneRequiredMiles || 0}
          chip={getMilestone(perks.currentPerks?.milestoneId || 0)?.chips}
          info={getMilestone(perks.currentPerks?.milestoneId || 0)?.milestoneDetails ?? ""}
          summary={getMilestone(perks.currentPerks?.milestoneId || 0)?.summary ?? ""}
          milestoneHeading={getMilestone(perks.currentPerks?.milestoneId || 0)?.milestoneHeading ?? ""}
          expandingButton={getMilestone(perks.currentPerks?.milestoneId || 0)?.expandingButton}
          perkBenefitsLst={(perks.currentPerks?.perkBenefitsLst ?? emptyPerks)?.map(
            (perk: { perkBenefitId: number }) => {
              const fetchedPerk = getPerk(perk.perkBenefitId);

              return {
                name: fetchedPerk?.perkSummary ?? "",
                iconId: fetchedPerk?.id ?? 0,
                items: fetchedPerk?.items,
              };
            }
          )}
        />
      </div>

      <div className="upcoming-sub-title">Upcoming milestones</div>
      <div className="milestone-container">
        <MPPerkProgressMilestone />
        <MPNextPerksMilestone />
        <MPPerksDetailsList
          isCurrentMilestone={false}
          mileStoneStatus={perks.nextPerks?.tierName ?? ""}
          oneworldTierstatus={perks.nextPerks?.oneWorldTier ?? ""}
          qualifyingMiles={perks.nextPerks?.milestoneRequiredMiles}
          chip={getMilestone(perks.nextPerks?.milestoneId)?.chips}
          info={getMilestone(perks.nextPerks?.milestoneId)?.milestoneDetails ?? ""}
          milestoneHeading={getMilestone(perks.nextPerks?.milestoneId)?.milestoneHeading ?? ""}
          expandingButton={getMilestone(perks.nextPerks?.milestoneId)?.expandingButton}
          perkBenefitsLst={perks.nextPerks?.perkBenefitsLst?.map((perk: { perkBenefitId: number }) => {
            const fetchedPerk = getPerk(perk.perkBenefitId);
            return {
              name: fetchedPerk?.perkSummary ?? "",
              iconId: fetchedPerk?.id ?? 0,
              items: fetchedPerk?.items,
            };
          })}
        />

        <MPPerksDetailsList
          isCurrentMilestone={false}
          mileStoneStatus={perks.futurePerks?.tierName ?? ""}
          oneworldTierstatus={perks.futurePerks?.oneWorldTier ?? ""}
          qualifyingMiles={perks.futurePerks?.milestoneRequiredMiles}
          chip={getMilestone(perks?.futurePerks?.milestoneId)?.chips}
          info={getMilestone(perks.futurePerks?.milestoneId)?.milestoneDetails ?? ""}
          milestoneHeading={getMilestone(perks.futurePerks?.milestoneId)?.milestoneHeading ?? ""}
          expandingButton={getMilestone(perks.futurePerks?.milestoneId)?.expandingButton}
          perkBenefitsLst={
            perks.futurePerks?.perkBenefitsLst?.map((perk: { perkBenefitId: number }) => {
              const fetchedPerk = getPerk(perk.perkBenefitId);
              return {
                name: fetchedPerk?.perkSummary ?? "",
                iconId: fetchedPerk?.id ?? 0,
              };
            }) as PerkBenefitItemProps[]
          }
        />

        <div className="divider"></div>
        <MPSeeAllPerksCta />
      </div>
    </>
  );
};

export default MPMilestoneContainer;
