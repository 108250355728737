import { useContext } from "react";

import Nav from "../MPPerksNavigation";
import { ACTIONS, PerksContext } from "../../context/PerksContext";
import MPPerksDetailsList from "../MPPerks/MPPerksContainer/MPPerksDetailsList";
import { ContentStateType } from "../../state/InitialState";
import { ContentContext } from "../../context/ContentContext";
import "./index.scss";
import RoundedButton from "../RoundedButton/RoundedButton";
import cxs from "cxs";
import { PerksScreens } from "../../helpers/constants";

const MPPerksLadder = () => {
  const { contentState }: { contentState: ContentStateType } = useContext(ContentContext);
  const { perksState, perksDispatch } = useContext(PerksContext);

  const { perks } = perksState;

  const { title, backgroundColor, buttonText, buttonTextColor, buttonBackgroundColor, link } =
    contentState?.Content[6]?.perksLadder || {};

  const styles = {
    container: cxs({
      backgroundColor: `var(--${backgroundColor}, #FBFFF6)`,
    }),
    backButton: cxs({
      color: `var(--${buttonTextColor}, #FFFFFF)`,
      backgroundColor: `var(--${buttonBackgroundColor}, #03314F)`,
    }),
  };

  const getMilestone = (milestoneId: number = 0) => {
    const milestone = contentState?.Content[5]?.milestones?.find((milestone) => milestone.id === milestoneId);
    return milestone;
  };

  const getPerk = (perkId: number) => {
    const perk = contentState?.Content[4]?.perks?.find((perk) => perk.id === perkId);

    if (perk) {
      return perk;
    }
  };

  const emptyPerks = [
    {
      perkBenefitId: 0,
      perkName: "",
      perkDescription: "",
      isEnabled: true,
    },
  ];

  const handleGoToPerks = async () => {
    perksDispatch({
      type: ACTIONS.SET_ACTIVE_SCREEN,
      value: { screenName: PerksScreens.Hub },
    });
  };

  return (
    <div className={`perks_ladder_container ${styles.container}`} data-testid="perks-ladder-container">
      <Nav
        title={title ?? "Mileage plan benefits"}
        returnToScreen={PerksScreens.Hub}
        customBackgroundColor={`var(--${backgroundColor}, #FBFFF6)`}
      />
      <div className="perks_ladder_content">
        {perks.allMilestonePerks.map((milestonePerk, index) => (
          <div key={milestonePerk.milestoneId} data-testid="milestone-perks-data">
            <MPPerksDetailsList
              key={milestonePerk.milestoneId}
              isCurrentMilestone={true}
              mileStoneStatus={milestonePerk?.tierName ?? ""}
              oneworldTierstatus={milestonePerk?.oneWorldTier ?? ""}
              qualifyingMiles={milestonePerk?.milestoneRequiredMiles || 0}
              chip={getMilestone(milestonePerk.milestoneId || 0)?.chips}
              info={getMilestone(milestonePerk.milestoneId || 0)?.milestoneDetails ?? ""}
              perksLadderText={getMilestone(milestonePerk.milestoneId || 0)?.perksLadderInfo?.text ?? ""}
              perksLadderTextShowIf={getMilestone(milestonePerk.milestoneId || 0)?.perksLadderInfo?.showIf ?? false}
              milestoneHeading={getMilestone(milestonePerk.milestoneId || 0)?.milestoneHeading ?? ""}
              expandingButton={getMilestone(milestonePerk.milestoneId || 0)?.expandingButton}
              perkBenefitsLst={(milestonePerk?.perkBenefitsLst ?? emptyPerks)?.map(
                (perk: { perkBenefitId: number }) => {
                  const fetchedPerk = getPerk(perk.perkBenefitId);
                  return {
                    name: fetchedPerk?.perkSummary ?? "",
                    iconId: fetchedPerk?.id ?? 0,
                    items: fetchedPerk?.items,
                  };
                }
              )}
            />
            {index < perks.allMilestonePerks.length - 1 && <div className="divider"></div>}
          </div>
        ))}
      </div>
      <div className="perks_ladder_back_button">
        <RoundedButton
          label={buttonText ?? "Back to Alaska perks"}
          className={styles.backButton}
          onClick={handleGoToPerks}
          type="button"
          testId="ladder-back-button"
        />
      </div>
      <div className="perks_ladder_tnc_container">
        <a className={`perks_ladder_tnc_cta`} href={link?.href}>
          {link?.text}
        </a>
      </div>
    </div>
  );
};

export default MPPerksLadder;
